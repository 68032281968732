/* eslint-disable max-len */
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  NodeAwardsFieldsFragment,
  NodeCareerPathwayFieldsFragment,
  NodeCommunityFieldsFragment,
  NodePostFieldsFragment,
  NodeProgramFieldsFragment,
  NodeSchoolFieldsFragment,
  NodeSpecialtyFieldsFragment,
} from '../../types/generated'
import Transformation from '../../types/Transformation'
import { getPublicImageTransformationUrl } from '../../utils'
import RenderIf from '../RenderIf'

type FragmentsUnion =
  | NodeSchoolFieldsFragment
  | NodeSpecialtyFieldsFragment
  | NodeAwardsFieldsFragment
  | NodeProgramFieldsFragment
  | NodeCommunityFieldsFragment
  | NodeCareerPathwayFieldsFragment
  | NodePostFieldsFragment

interface Props {
  header: FragmentsUnion
}

const DetailPageHeader = ({ header }: Props) => {
  // @ts-ignore
  const image = header?.relationships?.field_image?.[0]?.gatsbyImageData

  // @ts-ignore
  const icon =
    (header as NodeSpecialtyFieldsFragment)?.relationships?.field_icon_image?.relationships
      ?.field_media_image?.uri?.value ??
    (header as NodeSchoolFieldsFragment)?.relationships?.field_icon?.relationships
      ?.field_media_image?.uri?.value

  const iconTransformations: Transformation[] = [
    {
      operation: 'scale',
      parameters: {
        width: 128,
      },
    },
  ]

  // eslint-disable-next-line no-underscore-dangle
  const shouldRenderIcon = !!icon && header?.__typename !== 'node__school'

  return (
    <section className="max-w-7xl shadow rounded-2xl bg-gray-100 md:flex">
      <div
        className={`w-full${image ? ' md:w-1/2 ' : ' '}flex items-center justify-center p-4 md:p-8`}
      >
        <RenderIf condition={shouldRenderIcon}>
          <img
            src={getPublicImageTransformationUrl(icon ?? '', iconTransformations)}
            className="bg-sdusd-orange w-16 h-16 p-4 rounded-lg mr-4"
            alt=""
            loading="lazy"
          />
        </RenderIf>
        <h1
          className={`inline-block text-2xl md:text-3xl font-bold lg:text-4xl text-sdusd-orange-100${
            !shouldRenderIcon ? ' text-center' : ''
          }`}
        >
          {header?.title}
        </h1>
      </div>

      <RenderIf condition={!!image}>
        <GatsbyImage
          className="flex-shrink-0 w-full md:w-1/2 rounded-2xl md:rounded-tl-none md:rounded-bl-none rounded-tr-2xl rounded-br-2xl"
          image={image}
          loading="lazy"
          alt={
            (header as NodeSchoolFieldsFragment)?.relationships?.field_image?.[0]?.thumbnail?.alt ??
            (header as NodePostFieldsFragment)?.relationships?.field_post_image?.thumbnail?.alt ??
            ''
          }
        />
      </RenderIf>
    </section>
  )
}

export default DetailPageHeader
