import React from 'react'
import CustomLink from '../CustomLink'
import SchoolPreview from '../SchoolPreview'
import RenderIf from '../RenderIf'
import { Maybe, NodeSchoolFieldsFragment } from '../../types/generated'

interface SchoolsListingProps {
  schools: NodeSchoolFieldsFragment[]
  searchLink:
    | {
        __typename?: string
        title?: Maybe<string>
        uri?: Maybe<string>
      }
    | null
    | undefined
}

const SchoolsListing = ({ schools, searchLink }: SchoolsListingProps) => (
  <RenderIf condition={schools.length > 0}>
    <div className="mt-16">
      <RenderIf condition={!!searchLink}>
        <CustomLink
          to={searchLink?.uri ?? '/'}
          className="text-sdusd-blue border-sdusd-blue border-b-2"
        >
          {searchLink?.title} &rarr;
        </CustomLink>
      </RenderIf>
    </div>
    <ul className="grid grid-cols gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 my-8">
      {schools.map((school) => (
        <SchoolPreview key={school.id} school={school} />
      ))}
    </ul>
  </RenderIf>
)

export default SchoolsListing
