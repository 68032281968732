import React from 'react'
import {
  NodeAwardsFieldsFragment,
  NodeCareerPathwayFieldsFragment,
  NodeCommunityFieldsFragment,
  NodePostFieldsFragment,
  NodeProgramFieldsFragment,
  NodeSchoolFieldsFragment,
  NodeSpecialtyFieldsFragment,
} from '../../types/generated'
import ContentRender from '../ContentRender'
import DetailPageHeader from '../DetailPageHeader'

interface Props {
  content:
    | NodeSchoolFieldsFragment
    | NodeSpecialtyFieldsFragment
    | NodeAwardsFieldsFragment
    | NodeProgramFieldsFragment
    | NodeCommunityFieldsFragment
    | NodeCareerPathwayFieldsFragment
    | NodePostFieldsFragment
}

const DetailPageBody = ({ content }: Props) => (
  <>
    <div>
      <DetailPageHeader header={content} />
    </div>
    <section className="max-w-screen-md mx-auto my-16">
      <ContentRender>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content?.body?.value ?? '' }} />
      </ContentRender>
    </section>
  </>
)

export default DetailPageBody
